<template>
  <a-spin :spinning="spinning">
    <!-- <react-table
        :table-data="tableData"
        :total-items="totalItems"
        :showTotal="showTotalFun"
        :columns="columns"
        :scroll="{ x: 0 }"
        :hideRowSelection="true"
        :is-full="true"
        :current-page="pageNumber"
        @emitRefreshData="clearFilterAndRefresh"
        @emitSelectChange="updateSelectChange"
        @emitOnPageChange="updatePageChange"
        @emitShowSizeChange="updateShowSizeChange"
      > -->
    <ele-table
      :columns="columns"
      :table-data="tableData"
      :total-items="totalItems"
      :is-full="true"
      :current-page="pageNumber"
      :hide-row-selection="true"
      @emitRefreshData="clearFilterAndRefresh"
      @emitSelectChange="updateSelectChange"
      @emitOnPageChange="updatePageChange"
      @emitShowSizeChange="updateShowSizeChange"
      @emitLinkClick="linkClick"
    >
      <a-row :gutter="8">
        <a-col class="gutter-row" :span="6">
          <a-input
            v-model="searchDto.ProjectName"
            placeholder="项目名称"
            @click="selectProjectModal"
          >
          </a-input>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            v-model="searchDto.CompanyId"
            show-search
            :filter-option="filterOption"
            style="width: 100%"
            placeholder="签约公司"
            allowClear
          >
            <a-select-option
              v-for="item in PayCompanys"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-select
            allowClear
            v-model="searchDto.CustomerId"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            placeholder="客户名称"
          >
            <a-select-option
              v-for="item in Customers"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="2">
          <a-month-picker
            v-model="searchDto.BatchMonth"
            placeholder="统计月份"
            valueFormat="YYYY-MM"
            style="width: 100%"
          />
        </a-col>
        <a-col class="gutter-row" :span="2">
          <a-select
            v-model="searchDto.Type"
            style="width: 100%"
            show-search
            :filter-option="filterOption"
            placeholder="显示筛选"
          >
            <a-select-option
              v-for="item in FilterStatus"
              :key="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col class="gutter-row" :span="4">
          <a-button
            type="primary"
            @click="searchData"
            v-if="isGranted('Pages.SigningFeePermissions.Query')"
          >
            {{ l("Search") }}
          </a-button>
          <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
        </a-col>
        <a-col class="gutter-row" style="text-align: right" :span="2">
          <a-button-group>
            <a-button
              v-if="isGranted('Pages.SigningFeePermissions.Export')"
              @click="exportToExcel()"
            >
              <a-icon type="file-excel" />
              <span>{{ l("Export") }}</span>
            </a-button>
          </a-button-group>
        </a-col>
      </a-row>
    </ele-table>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import { fileDownloadService } from "@/shared/utils";
import SelectPageList from "@/components/linggong/select-page-list";
import { CommonServiceProxy } from "@/shared/common-service";
import realuserlist from "./realusermanage/realuserlist";
import signinglist from "./signingmanage/signinglist";
import smsusagelist from "./smsusagemanage/smsusagelist";

export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable },
  data() {
    return {
      spinning: false,
      searchDto: {
        Type: 1,
        ProjectId: "",
        ProjectName: "",
        CustomerId: undefined,
        CompanyId: undefined,
        BatchMonth: "",
        SkipCount: 0,
        MaxResultCount: 50,
      },
      commonService: null,
      totalItems: 0,
      pageNumber: 1,
      pagerange: [1, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      columns: [],
      PayCompanys: [],
      Customers: [],
      FilterStatus: [
        { label: "全部的项目", value: 0 },
        { label: "有数据项目", value: 1 },
      ],
    };
  },
  methods: {
    initColumns() {
      var vm = this;
      this.columns = [
        {
          title: "项目编码",
          dataIndex: "projectCode",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectCode" },
        },
        {
          title: "项目名称",
          dataIndex: "projectName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "projectName" },
        },
        {
          title: "实名人次",
          dataIndex: "realNameCount",
          align: "center",
          width: 150,
          type: "link",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text + " 人",
              },
              on: {
                click: function () {
                  vm.realUserClick(record, vm.searchDto.BatchMonth);
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "realNameCount" },
        },
        {
          title: "签署人次",
          dataIndex: "signCount",
          align: "center",
          width: 150,
          type: "link",
          customRender: function (text, record, index) {
            var child = vm.$createElement("a", {
              domProps: {
                innerHTML: text + " 人",
              },
              on: {
                click: function () {
                  vm.signingClick(record, vm.searchDto.BatchMonth);
                },
              },
            });
            var obj = {
              children: child,
              attrs: {},
              on: {
                click: () => {
                  this.$message.info(text);
                },
              },
            };
            return obj;
          },
          scopedSlots: { customRender: "signCount" },
        },
        // {
        //   title: "短信用量",
        //   dataIndex: "smsUsageCount",
        //   align: "center",
        //    width: 50,
        //   customRender: function (text, record, index) {
        //     var child = vm.$createElement("a", {
        //       domProps: {
        //         innerHTML: text + ' 人',
        //       },
        //       on: {
        //         click: function () {
        //           vm.smsUsageClick(record);
        //         },
        //       },
        //     });
        //     var obj = {
        //       children: child,
        //       attrs: {},
        //       on: {
        //         click: () => {
        //           this.$message.info(text);
        //         },
        //       },
        //     };
        //     return obj;
        //   },
        //   scopedSlots: { customRender: "smsUsageCount" },
        // },
        {
          title: "客户名称",
          dataIndex: "customerName",
          align: "center",
          // width: 100,
          scopedSlots: { customRender: "customerName" },
        },
      ];
    },
    realUserClick(item, batchMonth) {
      this.lookRealUserClick(item, batchMonth);
    },
    lookRealUserClick(item, batchMonth) {
      ModalHelper.create(
        realuserlist,
        {
          projectId: item ? item.projectId : "",
          yearMonth: batchMonth ? batchMonth : "",
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    signingClick(item, batchMonth) {
      this.lookSigningClick(item, batchMonth);
    },
    lookSigningClick(item, batchMonth) {
      ModalHelper.create(
        signinglist,
        {
          projectId: item ? item.projectId : "",
          yearMonth: batchMonth ? batchMonth : "",
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    smsUsageClick(item, batchMonth) {
      this.lookSmsUsageClick(item, batchMonth);
    },
    lookSmsUsageClick(item) {
      ModalHelper.create(
        smsusagelist,
        {
          projectId: item ? item.projectId : "",
        },
        {
          isChange: true,
          width: "1200px",
        }
      ).subscribe((res) => {
        if (res) {
          this.refreshGoFirstPage();
        }
      });
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }

          this.searchDto.ProjectId = projectIds;
          this.searchDto.ProjectName = projectNames;
          this.getData();
        }
      });
    },
    /**
     * ������
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          url: this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ�ͻ�
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * ��ȡ���� */
    getData() {
      this.spinning = true;
      let month = this.searchDto.BatchMonth;
      console.info(month);
      let options = {
        url:
          this.$apiUrl +
          "/api/services/app/ProjectUser/GetProjectSigningFeePaged",
        method: "GET",
        params: Object.assign(
          {
            type: this.searchDto.Type,
            projectId:
              this.searchDto.ProjectName == ""
                ? null
                : this.searchDto.ProjectId,
            customerId:
              this.searchDto.CustomerId == ""
                ? null
                : this.searchDto.CustomerId,
            companyId:
              this.searchDto.CompanyId == "" ? null : this.searchDto.CompanyId,
            yearMonth: this.searchDto.BatchMonth,
            skipCount: this.searchDto.SkipCount,
            maxResultCount: this.searchDto.MaxResultCount,
          },
          {
            projectId: null,
            projectIds: this.searchDto.ProjectId,
          }
        ),
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            this.totalPages = Math.ceil(
              data.totalCount / this.searchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.searchDto.MaxResultCount + 1,
              this.pageNumber * this.searchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item) => {
              return {
                ...item,
                realNameCount: item.realNameCount + " 人",
                signCount: item.signCount + " 人",
              };
            });
          }
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    searchData() {
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    /**
     * 导出为excel
     */
    async exportToExcel() {
      this.spinning = true;
      try {
        let res = await this.commonService.get({
          url: "/api/services/app/ProjectUser/GetToExcelFile",
          params: Object.assign(
            {
              type: this.searchDto.Type,
              projectId:
                this.searchDto.ProjectId == ""
                  ? null
                  : this.searchDto.ProjectId,
              customerId:
                this.searchDto.CustomerId == ""
                  ? null
                  : this.searchDto.CustomerId,
              companyId:
                this.searchDto.CompanyId == ""
                  ? null
                  : this.searchDto.CompanyId,
              yearMonth: this.searchDto.BatchMonth,
              skipCount: this.searchDto.SkipCount,
              maxResultCount: this.searchDto.MaxResultCount,
            },
            {
              projectId: null,
              projectIds: this.searchDto.ProjectId,
            }
          ),
        });
        console.log(res);
        fileDownloadService.downloadTempFile(res);
        this.spinning = false;
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    clearFilterAndRefresh() {
      this.searchDto.SkipCount = 0;
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    linkClick(newV) {
      const { item, index } = newV;
      if (index === 2) {
        this.realUserClick(item, this.searchDto.BatchMonth);
      } else if (index === 3) {
        this.signingClick(item, this.searchDto.BatchMonth);
      }
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.pageNumber = 1;
      this.searchDto.SkipCount = 0;
      this.searchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.pageNumber = page;
      this.searchDto.SkipCount = (page - 1) * pageSize;
      this.searchDto.MaxResultCount = pageSize;
      this.getData();
    },
    clearAndGetData() {
      this.searchDto.Type = 1;
      this.searchDto.ProjectId = "";
      this.searchDto.ProjectName = "";
      this.searchDto.CustomerId = undefined;
      this.searchDto.CompanyId = undefined;
      this.searchDto.BatchMonth = "";
      this.searchDto.SkipCount = 0;
      this.pageNumber = 1;
      this.getData();
    },
  },
  created() {
    this.commonService = new CommonServiceProxy(this.$apiUrl, this.$api);
    this.initColumns();
    this.getAllPayCompany();
    this.getAllCutomer();
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
